import { navigate } from "gatsby-link"
import React, { useState } from "react"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import axios from "axios"

const Login = () => {
  let token;
  if (typeof window !== 'undefined') {
    /* 
      At build phase we dont have access to localStorage
      because we are doing server side rendering (no browser)
    */
    token = localStorage.getItem("token")
  }
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  if (token) {
    localStorage.removeItem("token")
  }
  const buttonClickedHandler = () => {
    if (email && password) {
      const apiEndPoint = "https://smartop-back-preprod.azurewebsites.net/api/"
      axios
        .post(apiEndPoint + "users/login", { email, password })
        .then(result => {
          if (typeof window !== 'undefined') {
            localStorage.setItem("token", result.data.token)
          }
          navigate("/ajouter-article")
        })
        .catch(() =>
          toast.error("Email ou le mot de passe est incorrect!", {
            position: toast.POSITION.BOTTOM_CENTER,
          })
        )
    }
  }

  return (
    <div class="login-page">
      <div class="login-page">
        <div class="form">
          <div class="login">
            <div class="login-header">
              <h3>Se connecter</h3>
            </div>
          </div>
          <div class="login-form">
            <input
              value={email}
              onChange={e => setEmail(e.target.value)}
              type="text"
              placeholder="Email"
            />
            <input
              value={password}
              onChange={e => setPassword(e.target.value)}
              type="password"
              placeholder="password"
            />
            <button onClick={buttonClickedHandler}>login</button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}
export default Login
